/* eslint-disable complexity */
import React, { useEffect } from 'react';
import classNames from 'classnames';
import { isEmpty } from 'lodash';

import { RewardsType, RewardsFromRestaurantType } from 'types/api';
import { MODAL_TYPE } from 'helpers/modals';
import { ANALYTICS_EVENT_NAME, logAnalyticsEvent } from 'helpers/loggers';
import { getRewardsCopyAndModalConfig } from 'helpers/rewards';

import { RingProgress } from '@mantine/core';

import ButtonLink from 'primitives/ButtonLink';
import Switch from 'primitives/Switch';
import AchievedRewardSticker from 'svgs/AchievedRewardSticker';
import Sticker from 'svgs/Sticker';

import styles from './styles.module.scss';

interface RewardsCardProps {
  isLoggedIn: boolean;
  openModal: (arg: string) => void;
  restaurantId: string;
  rewards: RewardsType[];
  rewardsFromRestaurant: RewardsFromRestaurantType;
  rewardsOptIn: { restaurantId: string; optInStatus: boolean };
  setRewardsOptIn: (arg: {
    restaurantId: string;
    optInStatus: boolean;
  }) => void;
}

export default function RewardsCard({
  isLoggedIn,
  openModal,
  restaurantId,
  rewards,
  rewardsFromRestaurant,
  rewardsOptIn,
  setRewardsOptIn,
}: RewardsCardProps) {
  const {
    messages,
    numQualifiedOrders,
    orderThreshold,
    programDescription,
    progressValue,
    rewardMet,
    rewardsInProgress,
    rewardsProgress,
  } = getRewardsCopyAndModalConfig({ rewards, rewardsFromRestaurant });

  const { opted_in = true } = rewards?.[0] || {};

  const isNewRewardsDiner = isEmpty(rewards);

  const optInCopy = opted_in ? '' : 'Opt in to start earning rewards.';

  const ctaCopy = isLoggedIn ? optInCopy : 'Log in to start earning rewards.';

  const copy = rewardsInProgress
    ? rewardsProgress
    : `${programDescription} ${ctaCopy}`;

  const progressRingOrRibbon = rewardMet ? (
    <AchievedRewardSticker />
  ) : (
    <RingProgress
      size={48}
      thickness={4}
      roundCaps
      rootColor="#DAD8D2"
      sections={[{ value: progressValue, color: '#0E7A4A' }]}
    />
  );

  const leftVisualElement = rewardsInProgress ? (
    progressRingOrRibbon
  ) : (
    <Sticker />
  );

  const showOptInSwitch = isNewRewardsDiner && isLoggedIn;

  function showRewardsModal() {
    openModal(MODAL_TYPE.rewards, {
      isLoggedIn,
      messages,
      numQualifiedOrders,
      orderThreshold,
      rewardMet,
      rewardsProgress,
    });

    logAnalyticsEvent({
      eventName: ANALYTICS_EVENT_NAME.selectX,
      attributes: {
        name: 'learn-more-loyalty',
        source: 'checkout',
      },
    });
  }

  useEffect(() => {
    if (!rewardsOptIn.optInStatus) {
      logAnalyticsEvent({
        eventName: ANALYTICS_EVENT_NAME.selectX,
        attributes: {
          name: 'opt-out-loyalty',
          source: 'checkout',
        },
      });
    }
  }, [rewardsOptIn.optInStatus]);

  useEffect(() => {
    if (rewardsOptIn.restaurantId !== restaurantId) {
      setRewardsOptIn({ optInStatus: true, restaurantId });
    }
  }, []);

  // In case of user opted out, do not show card
  if (!isNewRewardsDiner && !opted_in) {
    return null;
  }

  return (
    <div className={styles.card}>
      <div className={styles.sticker}>{leftVisualElement}</div>
      <div className={styles.copyWrapper}>
        <div
          className={classNames(styles.copy, {
            [styles.congratsCopy]: rewardMet,
          })}
        >
          {copy}{' '}
          {rewardMet ? (
            'Applied at checkout.'
          ) : (
            <ButtonLink onClick={showRewardsModal} className={styles.copy}>
              Learn More
            </ButtonLink>
          )}
        </div>
      </div>
      {showOptInSwitch && (
        <div className={styles.switch}>
          <Switch
            isToggled={rewardsOptIn.optInStatus}
            onToggle={(toggleValue) =>
              setRewardsOptIn({ optInStatus: toggleValue, restaurantId })
            }
          />
        </div>
      )}
    </div>
  );
}
