/* eslint-disable complexity */
import React, { useEffect, useRef, useState, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { get, isEmpty } from 'lodash';
import MediaQuery from 'react-responsive';
import classNames from 'classnames';
import { InView } from 'react-intersection-observer';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import { parse } from 'query-string';

import { calculateNumItems } from 'helpers/order';
import {
  setIsDirectToMpRedirect,
  getIsDirectToMpRedirect,
} from 'helpers/configureRedirects';
import breakpoints from 'helpers/breakpoints';
import {
  ANALYTICS_EVENT_NAME,
  logAnalyticsEvent,
  logMessage,
} from 'helpers/loggers';
import { AUTH_MODAL_TYPE, MODAL_TYPE } from 'helpers/modals';
import {
  MAX_ORDER_CLOSURE_DESCRIPTION,
  MAX_LENGTH,
  ALLOWED_PIXEL_CHANNELS,
  NO_DIRECT_LIVE_CHANNEL,
} from 'helpers/constants';
import {
  getCnChannel,
  setCnChannel,
  getCnChannelXid,
  setCnChannelXid,
  getFbPixelId,
} from 'helpers/customer';
import { isValidString } from 'helpers/validation';
import { fetchFbPixel } from 'helpers/api';
import { getFbPixelScript } from 'helpers/url';
import { formatCuisineString } from 'helpers/format';
import { onHeightChangedHandler } from 'helpers/yelp';

import usePlatform from 'hooks/usePlatform';
import usePrevious from 'hooks/usePrevious';
import useScript from 'hooks/useScript';
import useFetchMenu from 'hooks/useFetchMenu';
import useResetTipsOnDeliveryUpdate from 'hooks/useResetTipsOnDeliveryUpdate';

import { CompanyContext } from 'context/CompanyContext';
import { MenuContext } from 'context/MenuContext';
import { ModalContext } from 'context/ModalContext';
import { UserContext } from 'context/UserContext';

import {
  getRestaurantDetails,
  setSelectedRestaurantId,
  getRestaurantIsLoading,
  getIsClosed,
} from 'modules/restaurant';
import { getOrderData, getIsLoading, yelpOpportunityXHR } from 'modules/order';

import Menu from 'containers/Menu';
import NotFound from 'containers/NotFound';
import UnsupportedBrowser from 'containers/UnsupportedBrowser';

import EmptyMenu from 'components/EmptyMenu';
import Navbar from 'components/Navbar';
import OrderingNotOpen from 'components/OrderingNotOpen';
import AppBanner from 'components/AppBanner';
import BranchIOBanner from 'components/BranchIOBanner';
import RestaurantMeta from 'components/RestaurantMeta';
import Footer from 'components/Footer';
import LoadingSpinner from 'primitives/LoadingSpinner';

import styles from './styles.module.scss';

const BANNER_TYPES = {
  nativeApp: 'nativeApp',
  branchIO: 'branchIO',
};

export function getCanSetPartnerChannel({ channel, existingChannel }) {
  return (
    channel &&
    channel !== existingChannel &&
    isValidString(channel) &&
    channel.length <= MAX_LENGTH.urlParam
  );
}

function Restaurant() {
  const { hqId, restaurantId } = useParams();
  const analyticsRestaurantId = useRef();
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  const { handleFetchCompany } = useContext(CompanyContext);
  const { menu, menuItems } = useContext(MenuContext);
  const { openModal } = useContext(ModalContext);
  const { user } = useContext(UserContext);

  const [isAppBannerIntersecting, setIsAppBannerIntersecting] = useState(true);
  const [isNavbarIntersecting, setIsNavbarIntersecting] = useState(false);

  const isClosed = useSelector(getIsClosed);
  const isOrderLoading = useSelector(getIsLoading);
  const isRestaurantLoading = useSelector(getRestaurantIsLoading);
  const order = useSelector(getOrderData);
  const restaurant = useSelector(getRestaurantDetails);
  const marketplaceLiveViaAdmin = restaurant && restaurant.is_discover_live;
  const isDirectLive = restaurant && restaurant.is_direct_live;
  const tips = order?.tips;

  const prevRestaurantId = usePrevious(restaurantId);
  const prevMenuId = usePrevious(menu.id);
  const prevFulfillmentMethod = usePrevious(order.fulfillmentMethod);
  const prevAllowDualTipping = usePrevious(tips?.allow_dual_tipping);

  const {
    isChowNowPlatform,
    isDirectPlatform,
    isMarketplacePlatform,
    isYelpPlatform,
  } = usePlatform();

  const isLoading = isRestaurantLoading || isOrderLoading;
  const numItems = calculateNumItems(order.items);

  const queryParams = parse(location.search);
  const cnChannel = getCnChannel();
  const cnChannelXid = getCnChannelXid();
  // in order to show Menu Preview we need the preview query param and access to a menu
  const isMenuPreview = !!(queryParams.menu_status === 'preview' && menuItems);
  const hasApp =
    restaurant && (restaurant.ios_app_store_id || restaurant.android_app_url);

  const restaurantPixelId = getFbPixelId();
  const isPixelChannel = ALLOWED_PIXEL_CHANNELS.includes(
    queryParams.cn_channel
  );
  const canAddScript = isPixelChannel && restaurantPixelId;
  const scriptBody =
    restaurantPixelId && getFbPixelScript(restaurantPixelId.pixelId);
  const shouldRemoveScript =
    restaurantPixelId && restaurantPixelId.restaurantId !== restaurantId;
  const isEmbedded = window.top !== window;
  // OBN restaurant FB pixel to be added only on initial load of restaurant with FB or IG channel.
  // if location switches, remove script and do not add again
  useScript(null, canAddScript && scriptBody, shouldRemoveScript && scriptBody);

  useFetchMenu({
    restaurantId,
    shouldFetchOnLoad: true,
    shouldRefetch: prevRestaurantId && prevRestaurantId !== restaurantId,
  });

  // We need to auto-reset tips when an order goes
  // over the delivery threshold from non-DMS -> DMS by
  // adding items on this page _after_ tipping.
  // See https://chownow.atlassian.net/browse/CN-37284?focusedCommentId=335350
  useResetTipsOnDeliveryUpdate({
    dispatch,
    pageLoaded: !isLoading,
    fulfillmentMethod: order?.fulfillmentMethod,
    allowDualTipping: tips?.allow_dual_tipping,
    prevFulfillmentMethod,
    prevAllowDualTipping,
  });

  function yelpSetFulfillmentMethod(opportunityToken) {
    dispatch({
      type: yelpOpportunityXHR.request.TYPE,
      payload: {
        opportunityToken,
      },
    });
  }

  // For Yelp platform, we must notify Yelp anytime the height of our Restaurant content changes
  useEffect(() => {
    if (isYelpPlatform) {
      onHeightChangedHandler(document.body.scrollHeight);
    }
  }, [document.body.scrollHeight]);

  useEffect(() => {
    handleFetchCompany(hqId);
  }, []);

  useEffect(() => {
    // Need an active menu or we get errors from
    // handleValidateOrder() in Order sagas
    if (isYelpPlatform && menu.id) {
      // TODO(JD): we can remove the check for opportunity_id once
      // https://github.com/ChowNow/cn-web-ordering/pull/170 is merged in
      // (ie since we'll no longer be going through Hermosa and will take the opportunity_token
      // directly from Yelp)
      const opportunityToken =
        queryParams?.opportunity_id ||
        queryParams?.opportunity_token ||
        'NO_TOKEN';

      yelpSetFulfillmentMethod(opportunityToken);
    }
  }, [menu.id]);

  useEffect(() => {
    // need both restaurant information and order information before firing event
    if (restaurant && !isEmpty(order)) {
      const { closures, is_live: isLive } = restaurant;
      let isOrderVolumeClosure = false;

      if (closures.length) {
        isOrderVolumeClosure = !!closures.find(
          (closure) => closure.description === MAX_ORDER_CLOSURE_DESCRIPTION
        );
      }

      const isRestaurantClosed = isClosed || !isLive || isOrderVolumeClosure;

      if (
        (!prevMenuId && menu.id) || // on initial load of menu
        (analyticsRestaurantId.current &&
          restaurantId !== analyticsRestaurantId.current) || // on switch of location
        isRestaurantClosed // if we get no menu because restaurant is closed
      ) {
        analyticsRestaurantId.current = restaurantId;
        const isOrderAhead = !!order.when;
        // only send atttribute when not on MP
        const isDirectEmbedded = isMarketplacePlatform
          ? undefined
          : window.top !== window;
        logAnalyticsEvent({
          eventName: ANALYTICS_EVENT_NAME.viewMenu,
          attributes: {
            menu_id: menu.id,
            has_cover_photo: !!restaurant.media?.cover_image_url,
            order_ahead_datetime: isOrderAhead ? order.when : '',
            order_is_asap: !isOrderAhead,
            restaurant_brand_id: hqId,
            restaurant_location_category: formatCuisineString(
              restaurant.cuisines
            ),
            restaurant_location_id: restaurantId,
            restaurant_location_name: restaurant.name,
            has_auto_promo: !isEmpty(restaurant.available_discounts),
            url: location.pathname,
            is_embedded_site: isDirectEmbedded,
            is_direct_to_marketplace_redirect: getIsDirectToMpRedirect(),
          },
        });
      }
    }
  }, [menu, restaurant]);

  useEffect(() => {
    const { cn_channel: cnChannelQuery } = queryParams;
    if (
      isDirectPlatform &&
      !restaurant?.is_direct_live &&
      restaurant?.is_ordering_network_live &&
      !cnChannel && // do not override OBN channel
      !cnChannelQuery
    ) {
      logMessage(`Adding cn_website query from restaurant container`);
      window.location.href = `${window.location.origin}${window.location.pathname}?cn_channel=${NO_DIRECT_LIVE_CHANNEL}`;
    }
  }, [restaurant]);

  useEffect(() => {
    // on load, if we have cn_channel, update session storage with channel
    // These need to match an enum within Hermosa.
    // https://github.com/ChowNow/Hermosa/blob/master/models/channel.py#L16
    const { cn_channel: cnChannelQuery } = queryParams;
    if (
      getCanSetPartnerChannel({
        channel: cnChannelQuery,
        existingChannel: cnChannel,
      })
    ) {
      setCnChannel(cnChannelQuery);
    }

    // on load, if we have cn_channel_xid, update session storage with channel xid
    // but only if we also have a channel as required by BE
    if (
      (queryParams?.cn_channel_xid &&
        queryParams?.cn_channel_xid !== cnChannelXid) ||
      (queryParams?.rwg_token && queryParams?.rwg_token !== cnChannelXid)
    ) {
      // in the case of both, rwg_token should be used
      const channelXid =
        queryParams.rwg_token || isValidString(queryParams.cn_channel_xid);

      if (channelXid && cnChannel) {
        setCnChannelXid(channelXid);
      }
    }

    if (queryParams.email && queryParams.verify && !user.id) {
      // on load, if we have forgot password queries, open auth modal to reset pw
      openModal(MODAL_TYPE.auth, {
        activeAuthModal: AUTH_MODAL_TYPE.resetPassword,
        email: queryParams.email,
        resetVerifyCode: queryParams.verify,
      });
      // remove queryParams;
      history.replace(location.pathname);
    }
  }, [queryParams]);

  useEffect(() => {
    if (prevRestaurantId !== restaurantId) {
      dispatch({
        type: setSelectedRestaurantId.TYPE,
        payload: restaurantId,
      });
    }
    // only fetch pixel id if we are on the first viewed restaurant, we do not already have a saved pixel id
    // and if we are in the facebook or instagram browser
    if (
      !prevRestaurantId &&
      restaurantId &&
      !restaurantPixelId &&
      isPixelChannel
    ) {
      fetchFbPixel(restaurantId);
    }
  }, [restaurantId]);

  function getPromoBanner() {
    if (!hasApp && !marketplaceLiveViaAdmin) {
      return false;
    }
    if (
      hasApp &&
      !isMarketplacePlatform &&
      !restaurant.display_chownow_app_banner
    ) {
      return BANNER_TYPES.nativeApp;
    }

    if (cnChannel) return false;

    return BANNER_TYPES.branchIO;
  }

  if (isLoading || prevRestaurantId !== restaurantId) {
    return <LoadingSpinner />;
  }

  if (
    !isLoading &&
    isMarketplacePlatform &&
    // Only show this when we have info back on if resto is MP live
    marketplaceLiveViaAdmin !== null &&
    !marketplaceLiveViaAdmin &&
    !isMenuPreview
  ) {
    // Redirect here so that we don't show Marketplace-Themed menus
    // for restos that don't have this feature via the Admin.
    // FIXME: this page is flashing even when there's a valid menu, need to fix
    return <NotFound isNotOnMP />;
  }

  const shouldRedirect = isEmbedded && !isDirectLive && !isYelpPlatform;

  if (shouldRedirect) {
    setIsDirectToMpRedirect();

    return (
      <UnsupportedBrowser
        isUnableToLoadSite
        isEmbedded
        isMpRedirect
        hqId={hqId}
        restoId={restaurantId}
      />
    );
  }

  if (restaurant && restaurant.company_id === hqId) {
    const {
      address,
      closures,
      display_properties: displayProperties,
      is_live: isLive,
      media,
      name,
      short_name: shortName,
    } = restaurant;

    let isOrderVolumeClosure = false;

    // CN-10963 using temp closure description to know if the closure was set by OrderBot
    if (closures.length) {
      isOrderVolumeClosure = !!closures.find(
        (closure) => closure.description === MAX_ORDER_CLOSURE_DESCRIPTION
      );
    }

    const menuHasItems =
      !!(menu.id && !isEmpty(menu.menu_categories, 'items')) || !menu.id;
    const displayMenu =
      isMenuPreview ||
      (!isClosed && isLive && !isOrderVolumeClosure && menuHasItems);
    const disclaimer = get(displayProperties, 'menu_disclaimer');
    const promoBanner = getPromoBanner();
    const ogImageUrl =
      media?.cover_image_url ||
      'https://getchownow.wpenginepowered.com/wp-content/uploads/resource-three.jpg';
    const ogTitle = `${name} | Menu | ${address.city} | ChowNow`;
    const title = `${shortName} Menu`;

    return (
      <>
        <RestaurantMeta
          ogImageUrl={ogImageUrl}
          ogTitle={ogTitle}
          title={title}
        />
        <MediaQuery maxWidth={breakpoints.xsMax}>
          {(isMobile) => (
            <>
              {isMobile && isChowNowPlatform && (
                <InView
                  onChange={(inView) => setIsAppBannerIntersecting(!inView)}
                >
                  {promoBanner && promoBanner === BANNER_TYPES.nativeApp && (
                    <AppBanner />
                  )}
                  {promoBanner && promoBanner === BANNER_TYPES.branchIO && (
                    <BranchIOBanner restaurant={restaurant} />
                  )}
                </InView>
              )}
              <InView
                onChange={(inView) => setIsNavbarIntersecting(inView)}
                rootMargin="2px 0px 0px 0px"
              >
                <Navbar
                  address={address}
                  restaurantName={shortName}
                  isRestaurant
                  isSticky={isAppBannerIntersecting}
                  hasOrderItems={!!numItems}
                />
              </InView>
              <div
                className={classNames(styles.restaurantWrapper, {
                  [styles.restaurantContent]: isClosed,
                  [styles.withDisclaimer]: disclaimer,
                  [styles.noFooter]: !isChowNowPlatform,
                })}
              >
                {displayMenu ? (
                  <>
                    {isEmpty(order) && !isMenuPreview && (
                      <EmptyMenu className={styles.blurredMenu} />
                    )}
                    {(!isEmpty(order) || isMenuPreview) && (
                      <Menu
                        stickyHeader={!isNavbarIntersecting}
                        disclaimer={disclaimer}
                        isMenuPreview={isMenuPreview}
                      />
                    )}
                  </>
                ) : (
                  <OrderingNotOpen
                    isOrderVolumeClosure={isOrderVolumeClosure}
                    is_live={isLive}
                    isMarketplacePlatform={isMarketplacePlatform}
                    restaurantName={shortName}
                  />
                )}
              </div>
            </>
          )}
        </MediaQuery>
        {isChowNowPlatform && <Footer />}
      </>
    );
  }

  return <NotFound />;
}

export default Restaurant;
