import { ReactComponent as IconSedan } from 'images/sedan-icon.svg';
import { ReactComponent as IconCoupe } from 'images/coupe-icon.svg';
import { ReactComponent as IconSuv } from 'images/suv-icon.svg';
import { ReactComponent as IconTruck } from 'images/truck-icon.svg';
import { ReactComponent as IconBike } from 'images/bike-icon.svg';
import { ReactComponent as IconOther } from 'images/van-icon.svg';

export const BACK_BTN_LABELS = {
  default: 'Back',
  locations: 'Back to Locations Map',
};

export const REORDER_CATEGORY_NAME = 'Order Again';

export const MAX_LENGTH = {
  urlParam: '32',
  textArea: '80',
  textBox: '140',
};

export const COUNTRIES = {
  usa: { name: 'usa', alpha2: 'US', alpha3: 'USA' },
  canada: { name: 'canada', alpha2: 'CA', alpha3: 'CAN' },
};

// Membership consts
export const MEMBER_NAME = 'member';
export const MEMBERSHIP_LABEL = 'membership';
export const MEMBERSHIP_STATUS = {
  active: 'active',
  canceled: 'canceled',
  expired: 'expired',
};

export const FAQ_LIST = [
  {
    id: 1,
    question: 'Does this membership auto-renew?',
    answer:
      // eslint-disable-next-line max-len
      `The ${MEMBERSHIP_LABEL} does not auto-renew and will end after the annual membership period is over.`,
  },
  {
    id: 2,
    question: 'How many orders per day do membership discounts get applied to?',
    answer:
      'One online order per day for each active member is eligible for the discount.',
  },
  {
    id: 3,
    question:
      'Will my discount apply to all restaurants if they have multiple locations?',
    answer: `The ${MEMBERSHIP_LABEL} is specific to the one location you are purchasing for.`,
  },
];

export const VEHICLE_TYPES = [
  { label: 'Sedan', icon: IconSedan, value: 'SEDAN' },
  { label: 'Coupe', icon: IconCoupe, value: 'COUPE' },
  { label: 'SUV', icon: IconSuv, value: 'SUV' },
  { label: 'Truck', icon: IconTruck, value: 'TRUCK' },
  { label: 'Bike', icon: IconBike, value: 'BIKE' },
  { label: 'Other', icon: IconOther, value: 'OTHER' },
];

export const VEHICLE_COLORS = [
  { label: 'Black', color: '#000000', value: 'BLACK' },
  { label: 'Gray', color: '#545355', value: 'GRAY' },
  { label: 'Silver', color: '#c6c6c6', value: 'SILVER' },
  { label: 'White', color: '#ffffff', value: 'WHITE' },
  { label: 'Red', color: '#d0021b', value: 'RED' },
  { label: 'Blue', color: '#1f36bb', value: 'BLUE' },
  { label: 'Green', color: '#00700e', value: 'GREEN' },
  { label: 'Gold', color: '#ddba08', value: 'GOLD' },
  { label: 'Other', value: 'OTHER' },
];

export const KEY_VALUES = {
  enter: 'Enter',
};

export const PLATFORM = {
  blueberry: 'blueberry',
  blueraspberry: 'blueraspberry',
  citrus: 'citrus',
  direct: 'direct',
  eggplant: 'eggplant',
  grape: 'grape',
  grass: 'grass',
  kiwi: 'kiwi',
  marketplace: 'marketplace',
  persimmon: 'persimmon',
  raspberry: 'raspberry',
  sage: 'sage',
  tomato: 'tomato',
  turquoise: 'turquoise',
  vanilla: 'vanilla',
  yelp: 'yelp',
};

// Used for display purposes
export const PLATFORM_DISPLAY = {
  direct: 'Direct Web',
  marketplace: 'Marketplace Web',
};

// https://github.com/ChowNow/Hermosa/blob/master/integrations/dms/constants.py
export const MANAGED_DELIVERY_STATUSES = {
  cancelled: 'cancelled',
  completed: 'completed',
  deliverd: 'delivered',
};

export const FEATURE_FLAGS = {
  allowDefaultMods: 'flag_default-mods',
  showPostOrderAdjustments: 'ab_post-order-adjustments',
  showRestaurantSaving: 'flag_restaurant-saving-enabled',
  showRestaurantTipping: 'flag_gxt-restaurant-tipping-enabled',
};

export const MENU_CATEGORY_IDS = {
  popularItems: '0', // popular item category will always be 0 from the backend
};

export const ALLOWED_PIXEL_CHANNELS = ['cn_facebook', 'cn_instagram'];

export const MAX_ORDER_CLOSURE_DESCRIPTION =
  'auto-closed - max number of orders not accepted';

export const CAN_ALPHA2_COUNTRY_CODE = 'CA';
export const CAN_CURRENCY = 'CAD';
export const USA_ALPHA2_COUNTRY_CODE = 'US';
export const USA_CURRENCY = 'USD';

export const SELECT_ITEM_OPERATIONS = {
  add: '0',
  edit: '1',
};

export const META_CHANNELS = {
  orderDirect: 'direct_web',
};

export const EXPERIMENT_COOKIE = 'cn_experiment_cookie_v2';
export const CONFIRMATION_NEW_USER_COOKIE =
  'cn_first_order_impact_confirmation';
export const CONFIRMATION_ROKT_ADS_COOKIE = 'cn_show_ads_confirmation';

// Injected via webpack locally and terraform in higher envs as process.env.REACT_APP_DEPLOY_ENV
export const DEPLOY_ENVIRONMENTS = {
  prod: 'prod',
  local: 'local',
  staging: 'stg',
  uat: 'uat',
  qa: 'qa',
  qa00: 'qa00',
  qa01: 'qa01',
  qa02: 'qa02',
  qa03: 'qa03',
};

export const DIRECT_ORIGINS = {
  prod: ['direct', 'order'],
  qa: 'app-order-direct',
};

export const MARKETPLACE_ORIGINS = {
  prod: 'www',
  qa: 'marketplace',
};

// FIXME: Need to fix whenever we allow custom membership plans, see CN-21244, CN-21239
// Only need id and name for this component
export const MEMBERSHIP_PLANS = [
  {
    id: 'plan_1',
    name: 'Bronze',
  },
  {
    id: 'plan_2',
    name: 'Silver',
  },
  {
    id: 'plan_3',
    name: 'Gold',
  },
];

export const YELP_EXPECTED_SEARCH_PARAMS = [
  'opportunity_token',
  'street_address1',
  'street_address2',
  'city',
  'state',
  'zip',
  'country',
  'fulfill_method',
];

export const GOOGLE_MAPS_API_KEYS = {
  direct: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  marketplace: process.env.REACT_APP_MARKETPLACE_GOOGLE_MAPS_API_KEY,
  yelp: process.env.REACT_APP_YELP_GOOGLE_MAPS_API_KEY,
};

export const YELP_SITES = ['m', 'www'];
export const YELP_LOCALES = {
  en_US: 'yelp.com',
  en_CA: 'yelp.ca',
};

export const ANALYTICS_EVENT_NAME = {
  addToCart: 'AddToCart',
  assessFeatureFlag: 'assess_feature_flag',
  attemptAddPaymentCard: 'attempt_add_payment_card',
  attemptAuthentication: 'attempt_authentication',
  checkout: 'Checkout',
  closeModal: 'close_modal',
  interactRestaurantImpactModuleMenu: 'interact_restaurant_impact_module_menu',
  interactSurveyTrigger: 'interact_survey_trigger',
  navigateCarousel: 'navigate_carousel',
  openModal: 'open_modal',
  proceedToPayment: 'proceed_to_payment',
  purchase: 'Purchase',
  purchaseFailure: 'purchase_failure',
  redirectToMarketplaceMenu: 'redirect_to_marketplace',
  selectAddItemsOrderSummary: 'select_add_items_order_summary',
  selectCustomerSupport: 'select_customer_support',
  selectCustomerSupportContact: 'select_customer_support_contact_option',
  selectDinerSeeYourImpact: 'select_diner_see_your_impact',
  selectDownloadApp: 'select_download_app',
  selectFulfillmentToggle: 'select_fulfillment_toggle',
  selectX: 'select_x',
  selectLoginEmail: 'select_login_email',
  selectMenuItem: 'select_menu_item',
  selectNestedModifier: 'select_nested_modifier',
  selectSaveRestaurant: 'select_save_restaurant',
  selectSignup: 'select_signup',
  selectSignupEmail: 'select_signup_email',
  selectSocial: 'select_social',
  selectTip: 'select_tip',
  submitAdjustTip: 'submit_adjust_tip',
  viewErrorPage: 'view_error_page',
  viewMenu: 'view_menu',
  viewMobileWebOrderSummary: 'view_mobile_web_order_summary',
  viewOpenTabPage: 'view_open_new_tab_page',
};

export const FAVORITED_TYPE = {
  restaurant: 'restaurant',
};

export const FIND_MORE_RESTAURANTS = {
  placeActionParam: 'place_action',
  deliveryFulfillmentQuery: '?deliversToMe=',
  deliveryLocationQuery: '&loc=',
};

export const NO_DIRECT_LIVE_CHANNEL = 'cn_website';
