import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import PlatformContext from 'context/PlatformContext';
import GoogleMapsContext from 'context/GoogleMapsContext';
import { GOOGLE_MAPS_API_KEYS, PLATFORM } from 'helpers/constants';

import LoadingSpinner from 'primitives/LoadingSpinner';

import styles from './styles.module.scss';

const CENTER_LATITUDE_SHIFT = 0.0025;
const MAP_ZOOM = 14;
function GoogleMap(props) {
  const { className, lat, lng, mapHeight } = props;

  const { platform } = useContext(PlatformContext);

  const [isDisabled, setIsDisabled] = useState(true);
  const { hasMapError } = useContext(GoogleMapsContext);

  function getMapUrl() {
    // Shift the center position down slightly so "View larger map" doesn't overlap the map pin
    const mapCenter = `${lat + CENTER_LATITUDE_SHIFT},${lng}`;
    const restoLatLng = `${lat},${lng}`;

    const platformFormKey =
      platform !== PLATFORM.marketplace && platform !== PLATFORM.yelp
        ? PLATFORM.direct
        : platform;
    const mapKey = GOOGLE_MAPS_API_KEYS[platformFormKey];

    // NOTE: causing non-passive event listener warnings https://issuetracker.google.com/issues/63211698
    // eslint-disable-next-line max-len
    return `https://www.google.com/maps/embed/v1/place?key=${mapKey}&q=${restoLatLng}&zoom=${MAP_ZOOM}&center=${mapCenter}`;
  }

  // Disabling the map on hover is necessary to prevent googlemaps from click-jacking
  // during automated tests - https://chownow.atlassian.net/browse/CN-8906
  return (
    <div
      onMouseEnter={() => setIsDisabled(false)}
      onMouseLeave={() => setIsDisabled(true)}
      onMouseOver={() => setIsDisabled(false)}
      onFocus={() => setIsDisabled(false)}
      className={styles.googleMapWrap}
      style={{ height: mapHeight }}
    >
      {hasMapError ? (
        <div
          style={{ height: mapHeight || '100%' }}
          className={classNames(styles.mapIframe, styles.mapError, className)}
        >
          Map is currently unavailable.
        </div>
      ) : (
        <>
          <LoadingSpinner />
          <iframe
            frameBorder="0"
            title="GoogleMapEmbed"
            src={getMapUrl()}
            style={{ height: mapHeight || '100%' }}
            className={classNames(styles.mapIframe, className, {
              [styles.disabled]: isDisabled,
            })}
            data-testid="GoogleEmbed"
          />
        </>
      )}
    </div>
  );
}

GoogleMap.propTypes = {
  className: PropTypes.string,
  lat: PropTypes.number,
  lng: PropTypes.number,
  mapHeight: PropTypes.string,
};

GoogleMap.defaultProps = {
  className: undefined,
  lat: undefined,
  lng: undefined,
  mapHeight: undefined,
};

export default GoogleMap;
