import { PLATFORM } from 'helpers/constants';
import { is, includes } from 'helpers/partial';

export const isChowNow = includes([
  PLATFORM.direct,
  PLATFORM.marketplace,
  PLATFORM.vanilla,
  PLATFORM.sage,
  PLATFORM.eggplant,
  PLATFORM.blueberry,
  PLATFORM.blueraspberry,
  PLATFORM.turquoise,
  PLATFORM.grass,
  PLATFORM.kiwi,
  PLATFORM.citrus,
  PLATFORM.persimmon,
  PLATFORM.tomato,
  PLATFORM.raspberry,
  PLATFORM.grape,
]);

export const isStandardPayment = isChowNow;

export const isDirect = includes([
  PLATFORM.direct,
  PLATFORM.grape,
  PLATFORM.vanilla,
  PLATFORM.sage,
  PLATFORM.eggplant,
  PLATFORM.blueberry,
  PLATFORM.blueraspberry,
  PLATFORM.turquoise,
  PLATFORM.grass,
  PLATFORM.kiwi,
  PLATFORM.citrus,
  PLATFORM.persimmon,
  PLATFORM.tomato,
  PLATFORM.raspberry,
]);

export const isMarketplace = is(PLATFORM.marketplace);

export const isYelp = is(PLATFORM.yelp);
