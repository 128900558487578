/* eslint-disable complexity, import/extensions */
import React, { useEffect, useState, useRef, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { get, has, isEmpty, last, some } from 'lodash';
import { Formik } from 'formik';
import classNames from 'classnames';
import { parse, stringify } from 'query-string';

import useDualTippingAllowed from 'hooks/useDualTippingAllowed';
import useFetchMenu from 'hooks/useFetchMenu';
import usePlatform from 'hooks/usePlatform';
import usePrevious from 'hooks/usePrevious';
import useApplePay from 'hooks/useApplePay';
import useGooglePay from 'hooks/useGooglePay';
import useSubmitOrder from 'hooks/useSubmitOrder';
import useIsMobile from 'hooks/useIsMobile';
import useResetTipsOnDeliveryUpdate from 'hooks/useResetTipsOnDeliveryUpdate';

import { CompanyContext } from 'context/CompanyContext';
import { MenuContext } from 'context/MenuContext';
import { ModalContext } from 'context/ModalContext';
import { UserContext } from 'context/UserContext';

import { reverseFormatExpDate, formatCuisineString } from 'helpers/format';
import { creditCardValidator } from 'helpers/validation';
import {
  NEW_CARD_VALUE,
  APPLE_PAY_STRUCT,
  GOOGLE_PAY_STRUCT,
  creditCardStringToType,
  getIsCardExpired,
} from 'helpers/payments';
import { FULFILLMENT_METHODS, getAppliedPromo } from 'helpers/order';
import {
  ABOVE_ORDER_PICKUP_MAX,
  BELOW_ORDER_PICKUP_MIN,
  BELOW_PICKUP_MINIMUM_WITH_DISCOUNT,
  BELOW_DELIVERY_MINIMUM_WITH_DISCOUNT,
} from 'helpers/api';
import { getSelectedAddress } from 'helpers/customer';
import { MAX_ORDER_CLOSURE_DESCRIPTION } from 'helpers/constants';
import { getCanShowRewards } from 'helpers/rewards';
import { buildRoute, ROUTES } from 'helpers/routes';
import { yelpScrollToTop } from 'helpers/yelp';
import {
  ANALYTICS_EVENT_NAME,
  createAnalyticsProduct,
  logAnalyticsCommerceEvent,
  logMessage,
} from 'helpers/loggers';
import { getIsDirectToMpRedirect } from 'helpers/configureRedirects';

import {
  getEditingItem,
  getErrorsForOrder,
  getOrderData,
  getOrderItemData,
  getIsOrderValidating,
  getIsManagedDelivery,
  getVehicle,
  initiateCheckout,
} from 'modules/order';
import {
  getFulfillmentForMethod,
  getIsTableRequired,
  getIsTippingEnabled,
  getRestaurantDetails,
  setSelectedRestaurantId,
} from 'modules/restaurant';

import BagErrors from 'components/BagErrors';
import Navbar from 'components/Navbar';
import Order from 'components/Order';
import ScrollToTop from 'components/ScrollToTop';
import Terms from 'components/Terms';
import PromoBanner from 'components/PromoBanner';
import Footer from 'components/Footer';
import Grid from 'primitives/Grid';
import LoadingSpinner from 'primitives/LoadingSpinner';

import { ReactComponent as IconPoweredBy } from 'images/icon-poweredby.svg';

import DiningInformation from './DiningInformation';
import OrderButton from './OrderButton';
import OrderFulfullmentDetails from './OrderFulfillmentDetails';
import PaymentSelection from './PaymentSelection';
import TipCalculator, { TIP_TYPE } from './TipCalculator';
import RestaurantTip from './RestaurantTip';
import RewardsCard from './RewardsCard';
import SingleUseItems from './SingleUseItems';

import { handleSubmitOrder, getHasUserEditedDeliveryAddress } from './helpers';

import styles from './styles.module.scss';

function Checkout() {
  const { hqId, restaurantId } = useParams();
  const history = useHistory();
  const location = useLocation();
  const isMobile = useIsMobile();

  const { isCanadian } = useContext(CompanyContext);
  const { openModal } = useContext(ModalContext);
  const { menu, isMenuLoading } = useContext(MenuContext);
  const {
    cardForm,
    handleFetchRewards,
    isLoggedIn,
    rewards,
    rewardsOptIn,
    selectedCard,
    selectedCardId,
    setRewards,
    setRewardsOptIn,
    user,
  } = useContext(UserContext);

  const dispatch = useDispatch();

  const [hasTableNameError, setTableNameError] = useState(false);
  const [hasComponentMounted, setHasComponentMounted] = useState(false);

  const restaurant = useSelector(getRestaurantDetails);
  const order = useSelector(getOrderData);
  const editingItemId = useSelector(getEditingItem);
  const errors = useSelector(getErrorsForOrder);
  const fulfillmentData = useSelector((state) =>
    getFulfillmentForMethod(state, order.fulfillmentMethod)
  );
  const hasActiveMenuId = has(menu, 'id');
  const isOrderValidating = useSelector(getIsOrderValidating);
  const isTableRequired = useSelector(getIsTableRequired);
  const isTippingEnabled = useSelector((state) =>
    getIsTippingEnabled(state, order.fulfillmentMethod)
  );
  const isManagedDelivery = useSelector(getIsManagedDelivery);
  const isRestaurantTipShown = useDualTippingAllowed();

  const orderItems = useSelector(getOrderItemData); // This aggregates more item data than 'order.items'
  const vehicle = useSelector(getVehicle);
  const tips = order?.tips;

  const prevRestaurant = usePrevious(restaurant);
  const prevEditingItemId = usePrevious(editingItemId);
  const prevUser = usePrevious(user);
  const prevFulfillmentMethod = usePrevious(order.fulfillmentMethod);
  const prevAllowDualTipping = usePrevious(tips?.allow_dual_tipping);

  const {
    isChowNowPlatform,
    isMarketplacePlatform,
    isStandardPaymentPlatform,
    isYelpPlatform,
  } = usePlatform();

  const isFooterShown = isStandardPaymentPlatform;

  const diningSection = useRef();
  const paymentSection = useRef();
  const isDelivery = order.fulfillmentMethod === FULFILLMENT_METHODS.delivery;
  const isCurbside = order.fulfillmentMethod === FULFILLMENT_METHODS.curbside;
  const isDineIn = order.fulfillmentMethod === FULFILLMENT_METHODS.dineIn;
  const selectedAddress = getSelectedAddress();
  const isNewDeliveryAddress = isDelivery && !selectedAddress?.id;
  const isEditedDeliveryAddress =
    isDelivery &&
    getHasUserEditedDeliveryAddress({
      savedAddresses: user?.delivery?.addresses,
      savedAddress: selectedAddress,
    });
  const isMissingOrderInfoOnLoad =
    (isEmpty(order) ||
      !order.fulfillmentMethod ||
      !get(order, 'items').length ||
      (isDelivery && !selectedAddress) ||
      (isCurbside && !vehicle)) &&
    !hasComponentMounted;

  const { isOrderSubmitting, submitOrder } = useSubmitOrder();

  const {
    defaultToApplePay,
    isApplePayLoading,
    isAttemptingApplePay,
    onApplePay,
  } = useApplePay(submitOrder);

  const {
    defaultToGooglePay,
    isGooglePayLoading,
    isAttemptingGooglePay,
    onGooglePay,
  } = useGooglePay(submitOrder);

  const isCashOnly = restaurant?.is_post_pay;
  const savedCards = user.billing?.cards || [];

  const isDefaultCardValid =
    (selectedCard && selectedCard.cvv_valid) || !selectedCard;
  const initialFormValues = {
    address: {
      zip: selectedCard?.address ? selectedCard.address.zip : '',
    },
    cvv: '',
    expDate: selectedCard?.exp_month
      ? reverseFormatExpDate(selectedCard.exp_month, selectedCard.exp_year)
      : '',
    id: isDefaultCardValid ? NEW_CARD_VALUE : selectedCard.id,
    is_visible: true,
    number: '',
    type: selectedCard?.type,
  };
  const isApplePay =
    !isCashOnly &&
    selectedCard &&
    selectedCard.type_id === creditCardStringToType.ApplePay;
  const isGooglePay =
    !isCashOnly &&
    selectedCard &&
    selectedCard.type_id === creditCardStringToType.GooglePay;
  const menuRoute = buildRoute({ hqId, restaurantId, route: ROUTES.menu });
  const hasOrderErrors =
    some(errors, { code: ABOVE_ORDER_PICKUP_MAX }) ||
    some(errors, { code: BELOW_ORDER_PICKUP_MIN }) ||
    some(errors, { code: BELOW_PICKUP_MINIMUM_WITH_DISCOUNT }) ||
    some(errors, { code: BELOW_DELIVERY_MINIMUM_WITH_DISCOUNT });
  const appliedPromo = getAppliedPromo(order.discounts);
  const showAvailablePromo = !isLoggedIn && order?.available_discounts?.length;
  const availablePromo = showAvailablePromo && order?.available_discounts;
  const canShowSingleUseItems =
    restaurant?.display_properties?.allows_order_single_use_items;
  const singleUseLabel =
    restaurant?.display_properties?.order_single_use_items_label;
  const rewardsFromRestaurant = restaurant?.rewards;
  const canShowRewards = getCanShowRewards({
    rewards,
    rewardsFromRestaurant,
    isYelpPlatform,
  });
  const canShowPromoBanner =
    !!(appliedPromo || showAvailablePromo) && !order.discounts?.[0]?.is_reward;

  useFetchMenu({
    restaurantId,
    when: order.when,
    shouldRefetch: !prevRestaurant && restaurant && !hasActiveMenuId,
  });

  const submitOrderValues = {
    cardForm,
    diningSection,
    errors,
    isApplePay,
    isDineIn,
    isGooglePay,
    isLoggedIn,
    isNewDeliveryAddress,
    isEditedDeliveryAddress,
    isTableRequired,
    isYelpPlatform,
    onApplePay,
    onGooglePay,
    openModal,
    order,
    restaurant,
    selectedCard,
    selectedCardId,
    setTableNameError,
    submitOrder,
    user,
  };

  function checkoutHasLoaded() {
    if (isYelpPlatform) {
      return restaurant && !isMenuLoading && prevUser !== undefined;
    }

    return (
      !isApplePayLoading &&
      !isGooglePayLoading &&
      restaurant &&
      !isMenuLoading &&
      prevUser !== undefined
    );
  }

  function getPaymentTypes() {
    const types = [];

    if (isCashOnly) {
      return 'cash_only';
    }

    if (!defaultToApplePay && !defaultToGooglePay && !last(savedCards)) {
      return 'card_form';
    }

    if (last(savedCards)) {
      types.push(last(savedCards).type.toLowerCase());
    }

    if (defaultToApplePay) {
      types.push('apple_pay');
    }

    if (defaultToGooglePay) {
      types.push('google_pay');
    }

    if (!last(savedCards)) {
      types.push('add_new_card');
    }

    return types.join(', ');
  }

  const checkoutLoaded = checkoutHasLoaded();

  useResetTipsOnDeliveryUpdate({
    dispatch,
    pageLoaded: checkoutLoaded,
    fulfillmentMethod: order?.fulfillmentMethod,
    allowDualTipping: tips?.allow_dual_tipping,
    prevFulfillmentMethod,
    prevAllowDualTipping,
  });

  useEffect(() => {
    // For Yelp, always scroll the viewport to the top
    if (isYelpPlatform) {
      yelpScrollToTop();
    }
  }, []);

  useEffect(() => {
    if (isMissingOrderInfoOnLoad) {
      history.push(menuRoute);
    }

    setHasComponentMounted(true);

    if (!restaurant) {
      dispatch({ type: setSelectedRestaurantId.TYPE, payload: restaurantId });
    }

    // this action triggers a validate order, only do this if menu is loaded
    if (hasActiveMenuId) dispatch({ type: initiateCheckout.TYPE });
  }, []);

  useEffect(() => {
    const isRestaurantMaxOrderTempClosed =
      restaurant &&
      restaurant.closures.length &&
      restaurant.closures.find(
        (closure) => closure.description === MAX_ORDER_CLOSURE_DESCRIPTION
      );

    if (isRestaurantMaxOrderTempClosed) {
      history.push(menuRoute);
    }
  }, [restaurant]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (isEmpty(user)) return setRewards([]);

    if (restaurantId) {
      handleFetchRewards({
        param: 'restaurant_id',
        id: restaurantId,
        includeOptOuts: true,
      });
    }
  }, [restaurantId, user]);

  useEffect(() => {
    if (checkoutLoaded && hasActiveMenuId) {
      const products = order.items.map((item) =>
        createAnalyticsProduct(
          item.name,
          item.id,
          item.price.toFixed(2),
          item.quantity,
          item.categoryName,
          isCanadian ? 'CAD' : 'USD'
        )
      );

      const isOrderAhead = !!order.when;
      const attributes = {
        delivery_fee_amount: order.delivery_fee || 0,
        has_delivery_fee: !!order.delivery_fee,
        has_misc_fee: !!get(order.misc_fee, 'amount'),
        is_direct_to_marketplace_redirect: getIsDirectToMpRedirect(),
        is_embedded_site: window.top !== window,
        managed_delivery_id: order.managed_delivery_id,
        menu_id: menu.id,
        misc_fee_amount: get(order.misc_fee, 'amount'),
        order_ahead_datetime: isOrderAhead ? order.when : '',
        order_is_asap: !isOrderAhead,
        order_is_reorder: !!order.originalOrderId,
        order_type: order.fulfillmentMethod,
        payment_type_displayed: getPaymentTypes(),
        restaurant_brand_id: restaurant.company_id,
        restaurant_location_category: formatCuisineString(restaurant.cuisines),
        restaurant_location_id: restaurant.id,
        restaurant_location_name: restaurant.name,
        total_amount: order.subtotal,
      };
      const marketplaceAttributes = {
        ...attributes,
        is_embedded_site: undefined, // only send atttribute when not on MP
        has_marketplace_diner_fee: !isEmpty(order.marketplace_diner_fee),
        marketplace_diner_fee_amount:
          get(order.marketplace_diner_fee, 'amount') || 0,
      };
      if (!attributes.order_type || !marketplaceAttributes.order_type) {
        logMessage(
          `Missing mParticle fulfill_method attribute fulfillmentMethod: ${order.fulfillmentMethod}`
        );
      }
      logAnalyticsCommerceEvent(
        ANALYTICS_EVENT_NAME.checkout,
        products,
        isMarketplacePlatform ? marketplaceAttributes : attributes
      );
    }
  }, [checkoutLoaded, hasActiveMenuId]);

  useEffect(() => {
    if (prevEditingItemId && !editingItemId) {
      const queryParams = parse(location.search);
      delete queryParams.order_item_id;
      history.replace(
        `${location.pathname}${queryParams && `?${stringify(queryParams)}`}`
      );
    }
  }, [editingItemId]);

  function handleFormSubmit(values, { setSubmitting }) {
    const isTableNotValid = isDineIn && isTableRequired && !order.tableName;

    if (isTableNotValid) {
      // Need to reset Formik submitted state if table is not valid
      setSubmitting(false);
    } else {
      submitOrder(values, setSubmitting);
    }
  }

  function getTipCalculatorTipType() {
    const isManagedDeliveryTip = isRestaurantTipShown || isManagedDelivery;
    return isManagedDeliveryTip
      ? TIP_TYPE.managedDelivery
      : TIP_TYPE.restaurant;
  }

  if (restaurant && !isMenuLoading && !isMissingOrderInfoOnLoad) {
    const {
      has_dine_in_promo_code: hasDineInPromoCode,
      has_promo_code: hasPromoCode,
    } = restaurant;

    const showPromoField =
      isStandardPaymentPlatform &&
      ((isDineIn && hasDineInPromoCode) || (!isDineIn && hasPromoCode));
    if (
      isStandardPaymentPlatform &&
      (isApplePayLoading || isGooglePayLoading)
    ) {
      return <LoadingSpinner />;
    }

    return (
      <div
        className={classNames(styles.checkout, {
          [styles.withMinHeight]: isChowNowPlatform,
        })}
      >
        <Helmet>
          <title>Checkout</title>
        </Helmet>
        <ScrollToTop />
        {(isOrderValidating || isOrderSubmitting) && (
          <div className={styles.fixedSpinner}>
            <LoadingSpinner overlay />
          </div>
        )}
        <div>
          <Navbar />
          <Formik
            initialValues={initialFormValues}
            validate={creditCardValidator}
            onSubmit={handleFormSubmit}
          >
            {(orderForm) => {
              const isSocialPaySelected =
                selectedCard?.id === APPLE_PAY_STRUCT.type ||
                selectedCard?.id === GOOGLE_PAY_STRUCT.type;
              const isCVVInvalid =
                !selectedCard?.cvv_valid ||
                (selectedCard?.cvv_valid && isNewDeliveryAddress);
              const isCardExpired = getIsCardExpired(
                selectedCard?.exp_month,
                selectedCard?.exp_year
              );
              const isCVVRequired =
                (isCVVInvalid || isCardExpired) &&
                (!orderForm?.values?.cvv || !!orderForm?.errors?.cvv);
              const isExpiryRequired =
                isCardExpired &&
                (!orderForm?.values?.expDate || !!orderForm?.errors?.expDate);
              const isSavedCardInvalid =
                isChowNowPlatform &&
                !isSocialPaySelected &&
                !isCashOnly &&
                selectedCard?.id !== 'new' &&
                (isCVVInvalid || isCardExpired) &&
                (isCVVRequired || isExpiryRequired);
              const isOrderBtnDisabled =
                orderForm.isSubmitting ||
                !orderItems.length ||
                hasOrderErrors ||
                isAttemptingApplePay ||
                isAttemptingGooglePay ||
                isSavedCardInvalid;

              return (
                <Grid container className={styles.checkoutWrapper}>
                  <Grid sm={12} md={7} lg={7}>
                    <div className={styles.checkoutDetails}>
                      <h2 className={styles.header}>
                        {isLoggedIn ? (
                          <>
                            Hey {user.first_name}, <br />
                            let&apos;s review your order.
                          </>
                        ) : (
                          "Let's review your order."
                        )}
                      </h2>
                      <div className={styles.section}>
                        {!isDineIn ? (
                          <OrderFulfullmentDetails
                            order={order}
                            restaurant={restaurant}
                          />
                        ) : (
                          <>
                            {isTableRequired && (
                              <div
                                ref={diningSection}
                                className={styles.diningSection}
                              >
                                <DiningInformation
                                  hasError={hasTableNameError}
                                />
                              </div>
                            )}
                            {isTippingEnabled && !isCashOnly && (
                              <div className={styles.section}>
                                <TipCalculator
                                  percentages={get(
                                    fulfillmentData,
                                    'tip.suggested_rates'
                                  )}
                                  restaurantName={restaurant.short_name}
                                  tipType={getTipCalculatorTipType()}
                                />
                              </div>
                            )}
                          </>
                        )}
                      </div>
                      {/* wrapping in isMobile check for automated tests */}
                      {isMobile && (
                        <div
                          className={classNames(
                            styles.section,
                            styles.orderInline
                          )}
                          sm={0}
                          md={5}
                          lg={4}
                        >
                          <Order isCheckout showOrderToggle />
                          {canShowPromoBanner && (
                            <div className={styles.section}>
                              <PromoBanner
                                promos={appliedPromo || availablePromo}
                              />
                            </div>
                          )}
                        </div>
                      )}
                      {isMobile && canShowRewards && (
                        <RewardsCard
                          rewards={rewards}
                          restaurantId={restaurantId}
                          rewardsOptIn={rewardsOptIn}
                          setRewardsOptIn={setRewardsOptIn}
                          rewardsFromRestaurant={rewardsFromRestaurant}
                          isLoggedIn={isLoggedIn}
                          openModal={openModal}
                        />
                      )}
                      {canShowSingleUseItems && (
                        <SingleUseItems label={singleUseLabel} />
                      )}
                      {isTippingEnabled && !isCashOnly && !isDineIn && (
                        <div
                          className={classNames(styles.section, {
                            [styles.extraMargin]: isYelpPlatform,
                          })}
                        >
                          <TipCalculator
                            percentages={get(
                              fulfillmentData,
                              'tip.suggested_rates'
                            )}
                            restaurantName={restaurant.short_name}
                            tipType={getTipCalculatorTipType()}
                          />
                        </div>
                      )}
                      {isRestaurantTipShown && (
                        <div className={styles.section}>
                          <RestaurantTip />
                        </div>
                      )}
                      {isStandardPaymentPlatform && (
                        <div className={styles.section} ref={paymentSection}>
                          <PaymentSelection
                            cardForm={cardForm}
                            defaultToApplePay={defaultToApplePay}
                            defaultToGooglePay={defaultToGooglePay}
                            isCashOnly={isCashOnly}
                            setFieldValue={orderForm.setFieldValue}
                            setTouched={orderForm.setTouched}
                            resetForm={orderForm.resetForm}
                            showPromoField={showPromoField}
                          />
                        </div>
                      )}
                    </div>
                    <div className={styles.btnWrapper}>
                      {!!errors.length && (
                        <div className={styles.errorsInline}>
                          <BagErrors linkTo={menuRoute} isCheckout />
                        </div>
                      )}
                      <div>
                        <OrderButton
                          onSubmit={() => {
                            handleSubmitOrder({
                              setFieldValue: orderForm.setFieldValue,
                              setSubmitting: orderForm.setSubmitting,
                              submitForm: orderForm.submitForm,
                              handleFormSubmit,
                              ...submitOrderValues,
                            });
                          }}
                          isOrderBtnDisabled={isOrderBtnDisabled}
                          total={order.total_due}
                          fulfillmentMethod={order.fulfillmentMethod}
                          isApplePaySelected={isApplePay}
                          isGooglePaySelected={isGooglePay}
                        />
                        {isCanadian && <Terms />}
                      </div>
                    </div>
                  </Grid>
                  {/* wrapping in isMobile check for automated tests */}
                  {!isMobile && (
                    <Grid
                      className={classNames(styles.section, styles.orderRight)}
                      sm={0}
                      md={5}
                      lg={4}
                    >
                      <OrderButton
                        onSubmit={() => {
                          handleSubmitOrder({
                            setFieldValue: orderForm.setFieldValue,
                            setSubmitting: orderForm.setSubmitting,
                            submitForm: orderForm.submitForm,
                            handleFormSubmit,
                            ...submitOrderValues,
                          });
                        }}
                        isOrderBtnDisabled={isOrderBtnDisabled}
                        total={order.total_due}
                        fulfillmentMethod={order.fulfillmentMethod}
                        isApplePaySelected={isApplePay}
                        isGooglePaySelected={isGooglePay}
                      />
                      {!!errors.length && (
                        <div className={styles.errorsRight}>
                          <BagErrors linkTo={menuRoute} isCheckout />
                        </div>
                      )}
                      <div className={styles.orderSummaryRight}>
                        <Order isCheckout showOrderToggle />
                      </div>
                      {canShowPromoBanner && (
                        <div className={styles.section}>
                          <PromoBanner
                            promos={appliedPromo || availablePromo}
                          />
                        </div>
                      )}
                      {canShowRewards && (
                        <RewardsCard
                          rewardsFromRestaurant={rewardsFromRestaurant}
                          restaurantId={restaurantId}
                          isLoggedIn={isLoggedIn}
                          rewards={rewards}
                          rewardsOptIn={rewardsOptIn}
                          setRewardsOptIn={setRewardsOptIn}
                          openModal={openModal}
                        />
                      )}
                      {isYelpPlatform && (
                        <div className={styles.poweredBy}>
                          <IconPoweredBy />
                        </div>
                      )}
                    </Grid>
                  )}
                </Grid>
              );
            }}
          </Formik>
        </div>
        {isFooterShown && (
          <div
            className={classNames(styles.footer, {
              [styles.tall]:
                selectedCard?.id === APPLE_PAY_STRUCT.type ||
                selectedCard?.id === GOOGLE_PAY_STRUCT.type,
            })}
          >
            <Footer />
          </div>
        )}
      </div>
    );
  }

  return <LoadingSpinner />;
}

export default Checkout;
