/* eslint-disable jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events, complexity */
import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { InView } from 'react-intersection-observer';

import { ModalContext } from 'context/ModalContext';
import { UserContext } from 'context/UserContext';

import { isOnPage } from '@chownow/cn-web-utils/url';
import { MODAL_TYPE } from 'helpers/modals';
import { formatDiscount, toTitleCase } from 'helpers/format';
import { ANALYTICS_EVENT_NAME, logAnalyticsEvent } from 'helpers/loggers';

import useIsMobile from 'hooks/useIsMobile';

import { ReactComponent as StickerPromo } from 'images/sticker-promo.svg';
import { ReactComponent as IconCaret } from 'images/icon-caret-left.svg';

import RewardsBanner from './RewardsBanner/index.tsx';

import styles from './styles.module.scss';

function PromoBanner({
  canShowRewardsBanner,
  isOrderBag,
  promos,
  rewards,
  rewardsFromRestaurant,
}) {
  const { openModal } = useContext(ModalContext);
  const { isLoggedIn, user } = useContext(UserContext);

  const [promoList, setPromoList] = useState(promos);
  const [activePromo, setActivePromo] = useState(promoList[0]);
  const [activePromoIndex, setActivePromoIndex] = useState(0);
  const [transformPx, setTransformPx] = useState('0');

  const isMobile = useIsMobile();
  const isCheckout = isOnPage('checkout');

  const shouldShowPagination = promoList.length > 1;
  const shouldShowLeftArrow = shouldShowPagination && activePromoIndex !== 0;
  const shouldShowRightArrow =
    shouldShowPagination && activePromoIndex < promoList.length - 1;

  useEffect(() => {
    if (canShowRewardsBanner) {
      const createPromoList = [
        {
          id: 'reward_banner',
          is_reward_banner: true,
        },
      ];
      const combineRewardsAndPromos = createPromoList.concat(promos);
      setPromoList(combineRewardsAndPromos);
    }
  }, [canShowRewardsBanner]);

  useEffect(() => {
    setActivePromo(promoList[activePromoIndex]);
  }, [activePromoIndex]);

  function openAuth(e) {
    e?.stopPropagation();

    const source = isOrderBag ? 'mobile_web_order_summary' : 'menu';
    logAnalyticsEvent({
      eventName: ANALYTICS_EVENT_NAME.selectX,
      attributes: { name: 'login_and_save', source },
    });

    openModal(MODAL_TYPE.auth);
  }

  function handlePromoClick(isRewardBanner) {
    if (isRewardBanner) {
      return null;
    }

    if (isOrderBag || (isCheckout && !isLoggedIn)) {
      return openAuth();
    }

    return openModal(MODAL_TYPE.dialog, {
      title: toTitleCase(activePromo.short_description),
      message: activePromo.long_description,
      confirmLabel: 'Sounds Good',
      isAlert: true,
    });
  }

  function handleShowNextPromo(e, direction) {
    e.stopPropagation();

    if (direction === 'next') {
      // to get transformPx, since banner is 100% of width, we can get the transform percentage by basing off the
      // current index. if we are navigating to the next available promo, take the current index + the next index and
      // that is the hundredth to transform to. ie changing from the first index (index 0) to the
      // second index in the array (index 1), we want to transform -100%
      // to accomodate margin, multiple the number if indexes by 16
      const nextIndex = activePromoIndex + 1;
      setTransformPx(`calc(-${nextIndex}00% - ${16 * nextIndex}px)`);
      setActivePromoIndex(nextIndex);
    } else {
      const previousIndex = activePromoIndex - 1;
      // if back to start of list, do not calculate with 16px margin
      if (previousIndex === 0) {
        setTransformPx('0');
      } else {
        setTransformPx(`calc(-${previousIndex}00% - ${16 * previousIndex}px)`);
      }
      setActivePromoIndex(previousIndex);
    }
  }

  function handleInView(inView, entry) {
    if (!isMobile) return; // only check for inView on mobile where carousel is scrollable
    if (inView) {
      const id = entry?.target?.children[0]?.id;
      const index = id.charAt(id.length - 1); // parse index from string
      setActivePromoIndex(index);
    }
  }

  // need to lowercase this way as trying to lowercase with CSS messes with truncation
  function getPromoDesc(promo) {
    if (isCheckout) {
      if (!isLoggedIn) {
        return (
          <>
            <span className={styles.loginUnderline}>Log in</span> to save up to{' '}
            {promo.description.split(' ')[0]} on your order.
          </>
        );
      }
      return `You're saving ${formatDiscount(promo.amount)} with promos, ${
        user?.first_name
      }!`;
    }

    if (isOrderBag) {
      return (
        <>
          <span className={styles.loginInline}>Log in</span> and save
        </>
      );
    }

    const { long_description: description } = promo;
    if (!isLoggedIn) {
      return `Log in to ${
        description.charAt(0).toLowerCase() + description.slice(1)
      }`;
    }

    return description;
  }

  return (
    <div className={styles.promoBannerWrapper}>
      <div
        className={classNames(styles.promoCarouselWrapper, {
          [styles.scrollable]: shouldShowPagination && isMobile,
        })}
      >
        {promoList.map((promo, index) => (
          <InView
            onChange={(inView, entry) => handleInView(inView, entry)}
            threshold={1}
            className={styles.bannerWrapper}
            style={{
              transform: `translateX(${transformPx})`,
              transition: 'transform 0.8s cubic-bezier(0.22, 1, 0.36, 1)',
            }}
            key={promo.id}
          >
            <div
              className={classNames(styles.promoBanner, {
                [styles.rewardsBanner]: promo?.is_reward_banner,
                [styles.small]: isOrderBag,
                [styles.extraPadding]: shouldShowPagination,
                [styles.fixedHeight]: shouldShowPagination,
              })}
              onClick={() => handlePromoClick(promo?.is_reward_banner)}
              id={`promo-${index}`}
            >
              {shouldShowLeftArrow && (
                <IconCaret
                  className={styles.iconCaret}
                  onClick={(e) => handleShowNextPromo(e, 'previous')}
                />
              )}
              {promo?.is_reward_banner ? (
                <RewardsBanner
                  isLoggedIn={isLoggedIn}
                  rewards={rewards}
                  rewardsFromRestaurant={rewardsFromRestaurant}
                />
              ) : (
                <>
                  <StickerPromo className={styles.sticker} />
                  <div
                    className={classNames(styles.promoDescriptionWrapper, {
                      [styles.leftAlign]: !isLoggedIn,
                    })}
                  >
                    <p
                      className={classNames(styles.promoDescription, {
                        [styles.noTruncation]: isCheckout,
                        [styles.largeText]: isCheckout,
                      })}
                    >
                      {getPromoDesc(promo)}
                    </p>
                    {!isLoggedIn && !isOrderBag && !isCheckout && (
                      <button
                        className={styles.login}
                        onClick={openAuth}
                        type="button"
                      >
                        Log in
                      </button>
                    )}
                  </div>
                </>
              )}
              {shouldShowRightArrow && (
                <IconCaret
                  className={classNames(styles.iconCaret, styles.iconRight)}
                  onClick={(e) => handleShowNextPromo(e, 'next')}
                />
              )}
            </div>
          </InView>
        ))}
      </div>
      {shouldShowPagination && (
        <div className={styles.pagination}>
          {promoList.map((promo) => (
            <div
              className={classNames(styles.paginationTab, {
                [styles.isActive]: promo.id === activePromo?.id,
              })}
              key={promo.id}
            />
          ))}
        </div>
      )}
    </div>
  );
}

PromoBanner.propTypes = {
  canShowRewardsBanner: PropTypes.bool,
  isOrderBag: PropTypes.bool,
  promos: PropTypes.arrayOf(
    PropTypes.shape({
      amount: PropTypes.number,
      id: PropTypes.string,
      long_description: PropTypes.string,
      short_description: PropTypes.string,
    })
  ).isRequired,
  rewards: PropTypes.arrayOf(PropTypes.shape()),
  rewardsFromRestaurant: PropTypes.shape({}),
};

PromoBanner.defaultProps = {
  canShowRewardsBanner: false,
  isOrderBag: false,
  rewards: [],
  rewardsFromRestaurant: {},
};

export default PromoBanner;
